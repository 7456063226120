import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    document.title = `zsoltbakos.hu`;
    });
  return (
    <div className="App">
      <header className="App-header">
        <p>
        </p>
        <a
          className="App-link"
          href="https://hermes.zsoltbakos.hu"
          rel="noopener noreferrer"
        >
          n&#613;&#729;so&#670;&#592;q&#647;losz//:sd&#647;&#647;&#613;
        </a>
      </header>
    </div>
  );
}

export default App;
